import '../tailwind.css'
import React, { useMemo, useState } from 'react'
import { Link } from 'gatsby'
import Tag, { colors } from '../components/Blog/Tag'
import capitalize from 'lodash/capitalize'
import Layout from '../components/layout'
import { PageTitle } from '../components/Common/PageTitle'
import { JoinNewsLetterButton } from '../components/Common/NewsLetterPlug'

const importAll = (r, cache) => r.keys().forEach(
  (key) => cache[key] = r(key)
)
const imageFiles = {}
importAll(require.context(`../content/posts/`, true, /\.(png|gif|jpg)$/), imageFiles)

const Tags = ({ selected, posts, onSelect }) => {
  const displayables = { all: 'bg-gray-100 text-gray-800', ...colors }
  return <div className="mt-12">
    <div className="sm:hidden">
      <label htmlFor="tabs" className="sr-only">Filter by tags</label>
      <select id="tabs" name="tabs"
              value={selected}
              onChange={(e) => {
                onSelect(e.target.value)
              }}
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        {Object.entries(displayables).map(([tag, color]) => {
          const amount = posts.filter(it => tag === 'all' || it.attributes.tags.includes(tag)).length
          return <option key={tag} value={tag}>{`${capitalize(tag)} (${amount})`}</option>
        })}
      </select>
    </div>
    <div className="hidden sm:block">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-4" aria-label="Tabs">
          {Object.entries(displayables).map(([tag, color]) => {
              const amount = posts.filter(it => tag === 'all' || it.attributes.tags.includes(tag)).length
              return <div key={tag} onClick={() => onSelect(tag)} className="cursor-pointer">
            <span
              className={`px-3 py-1 font-medium text-sm rounded-t-md ${color} ${tag === selected ? `underline` : undefined}`}>
                {capitalize(tag)}
              <span
                className="bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">{amount}</span>
              </span>
              </div>
            }
          )}
        </nav>
      </div>
    </div>
  </div>
}

const PostCard = ({ post, onSelectTag }) => {
  const imageFile = imageFiles[`./${post.slug}/poster.png`] ? imageFiles[`./${post.slug}/poster.png`].default : ''
  return <div className="flex flex-col rounded-lg shadow-lg overflow-hidden space-y-4 bg-gray-50">
    <div className="flex-shrink-0">
      <Link
        className="block mt-2 rounded-lg"
        to={`/blog/post/${post.slug}`}
      >
        <img className="h-48 w-full object-cover rounded-t-lg"
             src={imageFile}
             alt="" />
      </Link>
    </div>
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1">
        <p className="text-sm font-medium text-gray-600">
          {post.attributes.type}
        </p>
        <Link
          className="block mt-2"
          to={`/blog/post/${post.slug}`}
        >
          <p className="text-xl font-semibold text-gray-900">{post.attributes.title}</p>
          <p className="mt-3 text-base text-gray-500 whitespace-pre-wrap"
             dangerouslySetInnerHTML={{ __html: post.attributes.blurb }} />
        </Link>
      </div>
      <div className="mt-6 flex items-center">
        <div className="ml-3">
          <div className="flex space-x-1 text-sm text-gray-500">
            <time dateTime={post.attributes.date}>
              {new Date(post.attributes.date).toDateString()}
            </time>
            <span aria-hidden="true">
                  &middot;
                </span>
            <span>
                  {post.attributes.length} min read
                </span>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center">
        <div className="ml-3">
          {post.attributes.tags.map(it => <div key={it} className="cursor-pointer" onClick={() => onSelectTag(it)}>
            <Tag tag={it} />
          </div>)}
        </div>
      </div>
    </div>
  </div>
}

const BlogList = ({ pageContext: { posts } }) => {
  const [selected, setSelected] = useState(undefined)
  const filteredPosts = useMemo(() => posts.filter(it => {
    const hasTag = !selected || selected === 'all' || it.attributes.tags.includes(selected)
    return (!it.slug.includes('test.md')) && hasTag
  }), [selected])
  return <Layout description="
  Do you want to know what tools you need to get started with wax carving? Do you want to know what wax color you should get? Do you want to know how you can set up a quick bench when you don’t have space for a dedicated one?
All these topics and more are covered on the blog! A new post is published every other Tuesday. So you can keep coming back to learn more about wax carving."
  title="Wax Carvers - Blog">
    <div className="relative pt-16 pb-10 px-4 sm:px-6 lg:pt-24 lg:pb-8 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3">
        </div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <PageTitle title="Blog" subtitle="" />
        <Tags selected={selected} posts={posts} onSelect={setSelected} />
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {filteredPosts.map(post => {
            return <PostCard key={post.slug} post={post} onSelectTag={setSelected} />
          })}
        </div>
      </div>
    </div>
    <div className="mt-12">
      <JoinNewsLetterButton />
    </div>
  </Layout>

}

export default BlogList
